import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useLocalStorage } from '@blockworks/platform/hooks';

import { INVITE_TOKEN_KEY } from '../models';

export const useInviteToken = () => {
    const router = useRouter();
    const inviteToken = router.query.token as string;
    const { storedValue, setValue, clearValue } = useLocalStorage<string>(INVITE_TOKEN_KEY);

    useEffect(() => {
        if (inviteToken && inviteToken !== storedValue) {
            setValue(inviteToken);
        }
    }, [inviteToken, storedValue, setValue]);

    return {
        token: inviteToken ?? storedValue ?? '',
        setToken: (token: string) => setValue(token),
        clearToken: () => clearValue(),
    };
};
