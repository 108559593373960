import { useCallbackUrlParam, useInviteToken } from '@blockworks/session/hooks';
import { FlexBox, Link, Text } from '@blockworks/ui/components';

import { AccountSignInForm } from '@/components/auth/account-sign-in-form';
import { AuthLayout } from '@/layout/auth/auth-layout';
import { IMetaProps } from '@/layout/default-meta';
import { AppRoutes } from '@/services/auth/auth.routes';

export const getServerSideProps = async () => {
    return {
        props: {
            meta: {
                title: 'Sign In | Blockworks Research',
            },
        },
    };
};

export const SignInPage = () => {
    const { token: inviteToken } = useInviteToken();
    const callbackUrlFromQuery = useCallbackUrlParam();
    const callbackUrl = inviteToken ? AppRoutes.INVITE_TOKEN.pathname(inviteToken) : callbackUrlFromQuery;
    return (
        <AccountSignInForm center flexGrow={1} callbackUrl={callbackUrl}>
            <FlexBox gap="xs" center w="full">
                <Text size="sm" color="muted">
                    {`Don't have an account?`}
                </Text>
                <Link intent="primary" size="sm" fontWeight="medium" href={AppRoutes.SIGN_UP.pathname}>
                    Sign up
                </Link>
            </FlexBox>
        </AccountSignInForm>
    );
};

SignInPage.getLayout = (page: React.ReactElement, metaProps: IMetaProps) => (
    <AuthLayout
        metaProps={metaProps}
        headingText="Sign in to your account"
        subheadingText="Crypto's most powerful research platform."
    >
        {page}
    </AuthLayout>
);

export default SignInPage;
